/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, unlimited chat',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  graphQL: 'https://api.pp.weezchat.fr/graphql',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  extCode: 'ZA',
  gtm: 'GTM-K473RX9',
  gtmCountry: 'GTM-P4DKPMS',
  completeProfileInputs: ['email'],
  id: 'ck5zhwbewtew70b62pp8r72ac',
  dvp4m: true,
  gsv: '36Um4fUw3LqJhnzlG23SKSJs5uo5KQ2c9Hp1B5AzSHk',
  appLink: 'https://www.goswipi.com/redirect_stores',
  didomi: null,
};
